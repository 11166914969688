import React from "react"
import ProjectsData from "../components/projectsData"
import Head from "../components/head"

const ProjectsPage = () => {
  return (
    <section id="projects" className="projects__container">
      <Head />
      <ProjectsData />
    </section>
  )
}

export default ProjectsPage
