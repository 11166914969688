export default {
  expenseApp: {
    name: "Expensify",
    summary:
      "This web application is from a Udemy course. I have created new features and completed coding challenges like connecting Redux with React, testing with Jest, and connecting Firebase with Redux. New features I have added to this application are Facebook login system, confirmation modal when removing an expense, show number of hidden expenses in dashboard summary.",
    techStack: [
      "Javascript",
      "ReactJS",
      "Redux",
      "Node",
      "Express",
      "Firebase",
      "Sass",
      "Jest",
    ],
  },
  noteApp: {
    name: "NoteBeast",
    summary:
      "Note taking application built with ReactJS and Vanilla Javascript. Users are allow to create, edit, and delete notes which are ordered by recently created notes. Implemented localStorage API to load notes on page reload.",
    techStack: ["Javascript", "ReactJS", "Sass", "HTML5"],
  },
  nestedTodoApp: {
    name: "Organizer",
    summary:
      "Simple todo application with the ability to nest todos by categories. Built with Vanilla Javascript and manipulating the DOM. Users have the ability to create, edit, delete, and easily check off a todo for completion.",
    techStack: ["Javascript", "HTML5", "CSS3", "HandlebarsJS"],
  },
  foodRecipeApp: {
    name: "Food Recipe",
    summary:
      "Food Recipe application build with Vanilla Javascript. Fetch food recipe data from Forkify to be display on User Interface. This is a project from a Udemy course I took on modern Javascript and some of the technogolies I have learned are Webpack and Babel.",
    techStack: ["Javascript", "HTML5", "CSS3", "DOM Manipulation"],
  },
}
