import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import projectsData from "../components/projectsData/projectsData"

const ProjectsData = () => {
  const data = useStaticQuery(graphql`
    query Images {
      images: allFile {
        nodes {
          id
          childImageSharp {
            fixed(width: 475, height: 300) {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const images = data.images.nodes.map(image => {
    return image.childImageSharp.fluid
  })

  return (
    <div className="projects__innerContainer">
      {/* First Project */}
      <div className="projects__box">
        <div className="projects__innerBox">
          <h2 className="projects__title">{projectsData.expenseApp.name}</h2>
          <p className="projects__summary">{projectsData.expenseApp.summary}</p>
          <div>
            <p className="projects__tech">Tech Stack</p>
            <ul className="projects__skills">
              {projectsData.expenseApp.techStack.map(skill => (
                <li key={skill} className="projects__skill">
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="projects__innerBox">
          <Img className="projects__img" fluid={images[0]} />

          <div className="projects__links">
            {/**
          
            <a
              href="https://github.com/vincenttern/expensify_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              source code
            </a>
          */}

            <a
              href="https://react-course132-expensify.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              live demo
            </a>
          </div>
        </div>
      </div>
      {/* Second Project */}
      <div className="projects__box">
        <div className="projects__innerBox">
          <h2 className="projects__title">{projectsData.noteApp.name}</h2>
          <p className="projects__summary">{projectsData.noteApp.summary}</p>
          <div>
            <p className="projects__tech">Tech Stack</p>
            <ul className="projects__skills">
              {projectsData.noteApp.techStack.map(skill => (
                <li key={skill} className="projects__skill">
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="projects__innerBox">
          <Img className="projects__img" fluid={images[1]} />

          <div className="projects__links">
            {/**
          
            <a
              href="https://github.com/vincenttern/notes_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              source code
            </a>
          */}

            <a
              href="https://notebeast.netlify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              live demo
            </a>
          </div>
        </div>
      </div>

      {/* Third Project */}
      <div className="projects__box">
        <div className="projects__innerBox">
          <h2 className="projects__title">{projectsData.nestedTodoApp.name}</h2>
          <p className="projects__summary">
            {projectsData.nestedTodoApp.summary}
          </p>
          <div>
            <p className="projects__tech">Tech Stack</p>
            <ul className="projects__skills">
              {projectsData.nestedTodoApp.techStack.map(skill => (
                <li key={skill} className="projects__skill">
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="projects__innerBox">
          <Img className="projects__img" fluid={images[2]} />

          <div className="projects__links">
            {/**
            <a
              href="https://github.com/vincenttern/nested_todo"
              target="_blank"
              rel="noopener noreferrer"
            >
              source code
            </a>
          
          */}

            <a
              href="https://codepen.io/vincent-tern/project/full/ZoYyYw"
              target="_blank"
              rel="noopener noreferrer"
            >
              live demo
            </a>
          </div>
        </div>
      </div>

      {/* Fourth Project */}
      <div className="projects__box">
        <div className="projects__innerBox">
          <h2 className="projects__title">{projectsData.foodRecipeApp.name}</h2>
          <p className="projects__summary">
            {projectsData.foodRecipeApp.summary}
          </p>
          <div>
            <p className="projects__tech">Tech Stack</p>
            <ul className="projects__skills">
              {projectsData.foodRecipeApp.techStack.map(skill => (
                <li key={skill} className="projects__skill">
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="projects__innerBox">
          <Img className="projects__img" fluid={images[4]} />

          <div className="projects__links">
            {/**
          
            <a
              href="https://github.com/vincenttern/forkify_recipe"
              target="_blank"
              rel="noopener noreferrer"
            >
              source code
            </a>
          */}

            <a
              href="https://vincenttern.github.io/forkify_recipe"
              target="_blank"
              rel="noopener noreferrer"
            >
              live demo
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectsData
